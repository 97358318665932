const Dropdown = ({
  status,
  onSelected,
  options,
}: {
  status: string | undefined;
  onSelected: (status: string) => void;
  options: string[];
}) => {
  return (
    <div className="relative w-full lg:max-w-sm m-2">
      <select
        value={status}
        onChange={(e) => onSelected(e.target.value)}
        className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
      >
        <option value={""}></option>
        {options.map((x) => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
