import React, { useEffect, useState } from "react";
import axios from "axios";
import Dropdown from "./Dropdown";
import { Pharmacy } from "../../shared/pharmacy.entity";

function Dashboard() {
  const [orders, setOrder] = useState<Pharmacy[]>([]);
  const [isModalShow, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<{ status: string; reason: string; name: string; id: number } | null>(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedreason, setSelectedreason] = useState("");

  const fetch = () => {
    (async () => {
      const { data } = await axios.get<Pharmacy[]>("/pharmacy-orders");
      setOrder(data);
    })();
  };

  const update = (statuses: { status: string; reason: string }) => {
    (async () => {
      try {
        await axios.put<Pharmacy>(`/pharmacy-orders/${selectedOrder?.id}`, {
          ...selectedOrder,
          ...statuses,
        });
        setSelectedOrder(null);
        setShowModal(false);
        setSelectedStatus("");
        fetch();
      } catch (err) {}
    })();
  };

  const addComment = (id: number | undefined) => {
    (async () => {
      try {
        await axios.put<Pharmacy>(`/chemistryrx/${id}/comment`);
        fetch();
      } catch (err) {}
    })();
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="flex justify-center w-full px-10">
      <section className="w-full flex flex-col items-center">
        <h1 className="text-6xl">Orders</h1>

        <table className="table-auto border-collapse border border-slate-500 w-full lg:w-2/3">
          <thead>
            <tr className="px-10">
              <th className="border border-slate-600">Order Id</th>
              <th className="border border-slate-600">RX Signed</th>
              <th className="border border-slate-600">Pharmacy</th>
              <th className="border border-slate-600">Clinician</th>
              <th className="border border-slate-600">Drug</th>
              <th className="border border-slate-600">Status</th>
              <th className="border border-slate-600">Status Detail</th>
              <th className="border border-slate-600">Tracking Number</th>

              <th className="border border-slate-600"></th>
            </tr>
          </thead>
          <tbody>
            {orders.map((x: Pharmacy) => (
              <tr key={x.id}>
                <td className="border border-slate-600 p-4">{x.orderId}</td>
                <td className="border border-slate-600 p-4">{x.rxIds.map((r) => (typeof r === "string" ? "" : r.signed))}</td>
                <td className="border border-slate-600 p-4">{x.name}</td>
                <td className="border border-slate-600 p-4">{x.clinician}</td>
                <td className="border border-slate-600 p-4">{x.drug}</td>
                <td className="border border-slate-600 p-4">{x.status}</td>
                <td className="border border-slate-600 p-4">{x.reason}</td>
                <td className="border border-slate-600 p-4">{x.trackingNumber}</td>
                <td className="border border-slate-600 p-4">
                  <button
                    className="bg-orange-200 rounded p-2 min-w-[10rem] hover:scale-105 hover:bg-orange-300 mb-5"
                    onClick={() => {
                      setShowModal(true);
                      setSelectedOrder(x as any);
                    }}
                  >
                    <span className="">Edit</span>
                  </button>
                  <button
                    className="bg-orange-200 rounded p-2 min-w-[10rem] hover:scale-105 hover:bg-orange-300"
                    onClick={() => {
                      addComment(x.id);
                    }}
                  >
                    <span className="">Add comment</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <div className={`relative z-10 ${isModalShow ? "" : "hidden"}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                      Changing Status
                    </h3>
                    <div className="mt-2">
                      <Dropdown
                        status={selectedStatus || selectedOrder?.status}
                        onSelected={setSelectedStatus}
                        options={
                          selectedOrder?.name === "minimus"
                            ? ["awaiting_shipment", "shipped", "cancelled"]
                            : ["in_progress", "out_for_delivery", "completed", "cancelled", "error"]
                        }
                      />
                      {[selectedStatus, selectedOrder?.status].some((x) => x === "error") ? (
                        <Dropdown
                          status={selectedreason || selectedOrder?.reason}
                          onSelected={setSelectedreason}
                          options={[
                            "Bad Address",
                            "Duplicate Order",
                            "Missing Electronic Prescription",
                            "Refill too Soon",
                            "Returned to Sender",
                            "Clarification Required:  Allergies",
                            "Clarification Required:  Other Medications",
                            "Prescription Expired",
                            "No Refills Remaining",
                          ]}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() =>
                    update({
                      status: selectedStatus,
                      reason: selectedreason,
                    })
                  }
                >
                  Save
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
